import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http'
// angular
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
// rxjs
import { environment } from '@environments/environment'
import { BehaviorSubject, from, Subject, Subscription } from 'rxjs'
import { tap } from 'rxjs/operators'
import { AppService } from '../../../app.service'
import { proficloudData } from './proficloud.data'
import { statusOverlays } from './proficloud.status-overlays'
import { UiService } from './ui/ui.service'
// app
import { DateRange } from '@angular/material/datepicker'
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns'
import { KeycloakEventType, KeycloakService } from 'keycloak-angular'
import { PcStatusOverlayService } from '../../shared/services/pc-status-overlay/pc-status-overlay.service'
import { NavItem, NavItemChild } from '../components/navigation/navigation.model'
import { HttpBaseService } from './http-base.service'
import {
  AdminServiceAttributeValues,
  DateRangeStrings,
  DateRangeToken,
  DeviceInfo,
  Environments,
  HttpHeader,
  KeycloakData,
  LoginResponse,
  Member,
  MultiStatusOverlayContent,
  Organisation,
  RefreshResponse,
  SessionData,
  SSOSessionData,
  UserAttributeValues,
  ValidPeriods,
} from './proficloud.interfaces'

/**
 *    This codebase is now served in different flavors, depending on where it is hosted.
 *    With this method, you get the name of the application that is being served.
 */
export function getAppName(): 'proficloud' | 'chargeRepayService' {
  let app: 'proficloud' | 'chargeRepayService' = 'proficloud'
  if (window.location.host === 'localhost:3180' || window.location.host.includes('app.charge-repay')) {
    app = 'chargeRepayService'
  }

  return app
}

@Injectable()
export class ProficloudService {
  offerChargeRepayRedirect$ = new BehaviorSubject<boolean>(false)

  exchangeRefreshTokenIntervalId: ReturnType<typeof setTimeout>

  appEnvironemnt = environment

  // translations
  translation_subscriptions: Subscription[] = []

  // Temp hack so we know where to add a device to
  currentOrganisation: Organisation

  organisations: Organisation[]

  currentOrganisationMembers: Member[] = []

  filteredOrganisationMembers: Member[] = []

  // provides the users timezone.
  // currently is initialized from the browser settings
  // User might be able to change this in the future
  userTimezone: string

  // TODO: This needs to be extended to a method which will interpret any combination of encoded range and give the readable string
  // eg. '4D,2W' = '4 days, starting 2 weeks ago'
  public presetDateRanges: Record<DateRangeToken, string> = {
    '1D': 'Today',
    '1D,1D': 'Yesterday',
    '1DC': 'Last 24 hours',
    '1W': 'This week',
    '1W,1W': 'Last week',
    '1WC': 'Week to date',
    '1M': 'This month',
    '1M,1M': 'Last month',
    '1MC': 'Month to date',
    '1Y': 'This year',
    '1Y,1Y': 'Last year',
    '1YC': 'Year to date',
  }

  constructor(
    // angular
    public http: HttpClient,
    public router: Router,
    // app
    public app: AppService,
    private statusOverlay: PcStatusOverlayService,
    public translate: TranslateService,
    public ui: UiService,
    private auth: KeycloakService,
    private httpBase: HttpBaseService
  ) {
    ;(window as any).proficloud = this

    this.periodicallyTradeRefreshToken()

    this.userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    // Look for session data in localstorage
    const localStorageSessionData = this.loadObjectFromLocalstorage('keycloakData')
    if (localStorageSessionData) {
      this.keycloakData = localStorageSessionData
    }
  }

  public removeKeyFromLocalstorage(key: string) {
    localStorage.removeItem(key)
  }

  public loadObjectFromLocalstorage(key: string) {
    const storedJson = localStorage.getItem(key)
    if (storedJson) {
      try {
        return JSON.parse(storedJson)
      } catch (e) {
        console.warn('could not parse json', e, storedJson)
      }
    }
  }

  public saveObjectToLocalstorage(key: string, object: any) {
    if (!object) {
      return
    }
    localStorage.setItem(key, JSON.stringify(object))
  }

  /**
   *     Streams
   */

  userDataFetched$ = new Subject()

  sessionDecoded$ = new Subject<void>()

  /**
   *
   *      DATA
   *
   */

  settings = {
    tradeRefreshTokenInterval: 1000 * 60 * 5, // 5 minutes
  }

  grafanaLinks: { [key in Environments]: string } = {
    local: 'https://tsd.proficloud-dev.io',
    dev: 'https://tsd.proficloud-dev.io',
    staging: 'https://tsd.proficloud-staging.io',
    production: 'https://tsd.proficloud.io',
  }

  impulseAnalyticsLinks: { [key in Environments]: string } = {
    local: 'https://impulseanalytics.proficloud-dev.io',
    dev: 'https://impulseanalytics.proficloud-dev.io',
    staging: 'https://impulseanalytics.proficloud-staging.io',
    production: 'https://impulseanalytics.proficloud.io',
  }

  /**
   *    keycloak based single-sign-on
   */
  getLoginLink() {
    const redirectURI = window.location.origin + '/auth-callback'
    return `${this.httpBase.backendUrls.authBaseUrl}/realms/kaa/protocol/openid-connect/auth?client_id=${
      this.app.application.keycloakClientId
    }&redirect_uri=${encodeURIComponent(redirectURI)}&response_type=code&scope=openid+profile+email`
  }

  getLoginLinkVariableRedirectPath(redirectPath: string) {
    const frontendHost = window.location.origin
    return `${this.httpBase.backendUrls.authBaseUrl}/realms/kaa/protocol/openid-connect/auth?client_id=${this.app.application.keycloakClientId}&redirect_uri=${frontendHost}${redirectPath}&response_type=code&scope=openid+profile+email`
  }

  /**
   *    keycloak based registration
   */
  getRegistrationLink() {
    const frontendHost = window.location.origin
    return `${this.httpBase.backendUrls.authBaseUrl}/realms/kaa/protocol/openid-connect/registrations?client_id=${this.app.application.keycloakClientId}&response_type=code&scope=openid+profile+email&redirect_uri=${frontendHost}/registration-callback`
  }

  getDriverRegistrationLink() {
    const frontendHost = window.location.origin
    return `${this.httpBase.backendUrls.authBaseUrl}/realms/kaa/protocol/openid-connect/registrations?client_id=${this.app.application.keycloakClientId}&response_type=code&scope=openid+profile+email&redirect_uri=${frontendHost}/driver-registration-callback`
  }

  followRegistrationLink() {
    window.location.href = this.getRegistrationLink()
  }

  followDriverRegistrationLink() {
    window.location.href = this.getDriverRegistrationLink()
  }

  loginCredentials: { username: string | undefined; password: string | undefined } = {
    username: undefined,
    password: undefined,
  }

  proficloudData = proficloudData

  statusOverlays = statusOverlays

  keycloakData: KeycloakData = {
    session: undefined,
    access_token: undefined,
    refresh_token: undefined,
    userDetails: undefined,
  }

  /**
   *
   *       STREAMS
   *
   */

  // TODO: All these need to go somewhere else
  loggedOut$ = new Subject<void>()
  organisationsListed$ = new Subject()
  organisationSwitched$ = new Subject<Organisation>()
  organisationSwitchBegun$ = new Subject()
  initialFragmentUUID$ = new BehaviorSubject<string>('')
  subscriptionsFetched$ = new Subject()
  loginEmailInput$ = new Subject<string>()

  // login email
  loginEmailChanged$ = this.loginEmailInput$
    .pipe(
      tap((value) => {
        this.loginCredentials.username = value
      })
    )
    .subscribe()

  // login password
  loginPasswordInput$ = new Subject<string>()
  loginPasswordChanged$ = this.loginPasswordInput$
    .pipe(
      tap((value) => {
        this.loginCredentials.password = value
      })
    )
    .subscribe()

  logoutClick$ = new Subject<void>()
  logoutTap$ = this.logoutClick$
    .pipe(
      tap(() => {
        this.performLogout()
      })
    )
    .subscribe()

  showResetTokenOverlay$ = new Subject<DeviceInfo | false>()
  showTestOverlay$ = new Subject<boolean | false>()
  multiStatusOverlay$ = new Subject<MultiStatusOverlayContent | false>()

  /**
   *
   *
   *      FUNCTIONS
   *
   */

  public logoutOnUnauthorised(err: HttpErrorResponse) {
    if (err.status === 401) {
      this.performLogout()
    }
  }

  public performLogout(redirectUrl?: string) {
    console.warn('Performing logout.')
    // clear session in localstorage
    this.clearUserSesssion()
    this.removeKeyFromLocalstorage('keycloakData')
    // clear session in session storage
    this.auth.logout()
    // broadcast logout event
    this.loggedOut$.next()
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    // revoke session on keycloak, and let keycloak redirect to root
    const locationOrigin = encodeURIComponent(window.location.origin)
  }

  private periodicallyTradeRefreshToken() {
    // Don't do this if it's toggled off
    if (!this.proficloudData.settings.toggles.exchangeToken) {
      return
    }

    // Don't do this if we already have an interval set
    if (this.exchangeRefreshTokenIntervalId) {
      return
    }

    // Don't do this if the user is not authenticated
    if (!this.keycloakData.session) {
      return
    }
  }

  listenForTokenRefresh() {
    this.auth.keycloakEvents$.subscribe({
      next: (event) => {
        console.log(`KC EVENT: ${JSON.stringify(event)}`)
        if (event.type == KeycloakEventType.OnTokenExpired) {
          from(this.auth.updateToken()).subscribe({
            next: () => {
              console.warn('Token expired. Refreshing.')

              const instance = this.auth.getKeycloakInstance()
              if (instance.token && instance.refreshToken) {
                localStorage.setItem('access_token', instance.token)
                localStorage.setItem('refresh_token', instance.refreshToken)
              } else {
                console.error(`Token refresh failed. Logging out.`)
                this.performLogout()
              }

              const sessionData: SSOSessionData = {
                access_token: localStorage.getItem('access_token'),
                refresh_token: localStorage.getItem('refresh_token'),
              }
              // Load the auth data from session storage (stored by the oauth lib)
              this.decodeSingleSignOnSession(sessionData)
            },
            error: (err) => {
              console.error(`Token refresh failed. Logging out due to: ${err}`)
              this.performLogout()
            },
          })
        }
      },
    })
  }

  createAccount(email: string, password: string) {
    const data = {
      url: `${this.httpBase.backendUrls.authBaseUrl}/admin/realms/kaa/users`,
      redirectUri: `${window.location.origin}/authenticate#login`,
      data: {
        email: email,
        enabled: 'true',
        emailVerified: 'false',
        username: email,
        credentials: [
          {
            type: 'password',
            value: password,
            temporary: 'false',
          },
        ],
      },
    }
    this.http
      .post<HttpResponse<{ data: LoginResponse }>>(this.httpBase.backendUrls.middlewareUrl + '/user', data, {
        observe: 'response',
      })
      .subscribe({
        next: (res) => {
          if (res.status === 201) {
            this.statusOverlay.showStatus({
              message: 'Your account has been created. Please verify your email address to continue.',
              type: 'success',
              closeable: true,
            })
            this.proficloudData.ui.authType = 'login'

            // Note: make separate hacky call to enable user PKI on succesful account creation
            // Note: Temporarily disabled until the backend is working
            this.enableUserPKI(email)
          }
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === 409) {
            this.statusOverlay.showStatus({
              message: 'A user with this email address already exists.',
              type: 'error',
              closeable: true,
            })
          } else {
            this.statusOverlay.showStatus({
              message: 'A problem occurred on our servers - sorry about that! Please try again later.',
              type: 'error',
              closeable: true,
            })
          }
        },
      })
  }

  enableUserPKI(email: string) {
    const data = {
      email,
    }
    this.http
      .post<HttpResponse<{ data: LoginResponse }>>(this.httpBase.backendUrls.middlewareUrl + '/userPKI', data, {
        observe: 'response',
      })
      .subscribe({
        error: (err: HttpErrorResponse) => {
          this.logoutOnUnauthorised(err)
          console.log(err)
          throw new Error(`Failed to enable user PKI: ${JSON.stringify(err)}`)
        },
      })
  }

  getHeaderDict() {
    if (!this.keycloakData.session) {
      return {
        'Content-Type': 'application/json',
      }
    }
    const currentAccessToken = this.keycloakData.session.access_token
    if (!currentAccessToken) {
      return
    }
    const existingHeader = this.headerHistory[currentAccessToken]
    if (existingHeader) {
      return existingHeader
    }

    const newHeader: HttpHeader = {
      Authorization: `Bearer ${(this.keycloakData.session as SessionData).access_token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      // Required header for add device in order to trigger policy
      // We can get it from IAM
      // There must be a dropdown box during device creation, to selection the correct group (organisation)
      // The following header is necessary in order to enable the group policy stored in keycloak
      // 'X-Security-Assume': "$group_name"
    }
    this.headerHistory[currentAccessToken] = newHeader
    return newHeader
  }

  getAuthHeader() {
    return {
      Authorization: `Bearer ${(this.keycloakData.session as SessionData).access_token}`,
    }
  }

  getPrivateApiHeaders(apiSecret: string) {
    const newHeader: HttpHeader = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Headers': '*',
      'API-Secret': apiSecret,
    }
    return newHeader
  }

  headerHistory: Record<string, HttpHeader> = {}

  login(username: string, password: string) {
    const loggedIn$ = new Subject<void>()

    const middlewareUrl = `${this.httpBase.backendUrls.middlewareUrl}/login`

    const proxyPayload = {
      username,
      password,
    }
    this.http.post(middlewareUrl, proxyPayload, { observe: 'response' }).subscribe((res: HttpResponse<{ data: SessionData }>) => {
      const responseData = res.body?.data
      if (!responseData) {
        return
      }
      this.decodeSessionResponse(responseData)
      loggedIn$.next()
    })

    return loggedIn$
  }

  confirmPassword(password: string) {
    const middlewareUrl = `${this.httpBase.backendUrls.middlewareUrl}/login`

    const proxyPayload = {
      username: this.keycloakData.userDetails?.data.email,
      password,
    }

    return this.http.post<LoginResponse>(middlewareUrl, proxyPayload)
  }

  decodeSessionResponse(session: SessionData) {
    this.keycloakData.session = session
    if (!this.keycloakData.session.access_token) {
      return
    }
    this.keycloakData.access_token = this.parseJwt(this.keycloakData.session.access_token)
    if (!this.keycloakData.session.refresh_token) {
      return
    }
    this.keycloakData.refresh_token = this.parseJwt(this.keycloakData.session.refresh_token)

    // Persist to localstorage
    this.saveObjectToLocalstorage('keycloakData', this.keycloakData)

    this.sessionDecoded$.next()
  }

  decodeSingleSignOnSession(session: SSOSessionData) {
    // Parse SSOSessionData values
    this.keycloakData.session = {
      access_token: session.access_token,
      refresh_token: session.refresh_token,
    }
    if (!this.keycloakData.session.access_token) {
      return
    }
    this.keycloakData.access_token = this.parseJwt(this.keycloakData.session.access_token)
    if (!this.keycloakData.session.refresh_token) {
      return
    }
    this.keycloakData.refresh_token = this.parseJwt(this.keycloakData.session.refresh_token)

    // Persist to localstorage
    this.saveObjectToLocalstorage('keycloakData', this.keycloakData)

    this.sessionDecoded$.next()
  }

  decodeRefreshResponse(refreshData: RefreshResponse) {
    this.keycloakData.access_token = this.parseJwt(refreshData.data.access_token)
    this.keycloakData.refresh_token = this.parseJwt(refreshData.data.refresh_token)
    if (!this.keycloakData.session) {
      return
    }
    this.keycloakData.session.access_token = refreshData.data.access_token
    this.keycloakData.session.refresh_token = refreshData.data.refresh_token

    // Persist to localstorage
    this.saveObjectToLocalstorage('keycloakData', this.keycloakData)
  }

  clearUserSesssion() {
    this.keycloakData.session = undefined
    this.keycloakData.access_token = undefined
    this.keycloakData.refresh_token = undefined
    this.keycloakData.userDetails = undefined
  }

  public hideInProductionUnless(flag: UserAttributeValues) {
    if (this.app.environment.frontend === 'production') {
      return !this.userHasFeatureFlag(flag)
    }
    return false
  }

  public userHasFeatureFlag(flag?: UserAttributeValues) {
    return !!flag && this.keycloakData.userDetails?.data?.attributes?.[flag]?.[0] === 'true'
  }

  public userHasAdminFeatureFlag(flag?: UserAttributeValues) {
    if (!flag) {
      return
    }
    const values = this.keycloakData.userDetails?.data?.attributes?.[flag]?.[0].split(',')
    return values?.length && (values.includes('true') || values.includes('uuid') || values.includes('dt') || values.includes('fw'))
  }

  public userHasAdminSectionFeatureFlag(section: AdminServiceAttributeValues) {
    const values = this.keycloakData.userDetails?.data?.attributes?.['AdminService']?.[0].split(',')
    return !!values && (values.includes('true') || values.includes(section))
  }

  parseJwt(token: string) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  }

  testParseJwt() {
    // eslint-disable-next-line max-len
    const token =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
    const expeceted = {
      sub: '1234567890',
      name: 'John Doe',
      iat: 1516239022,
    }
    const result = this.parseJwt(token)
    const passed = JSON.stringify(result) === JSON.stringify(expeceted)
    return {
      passed,
    }
  }

  getVersion() {
    return this.http.get('/version.txt')
  }

  isTokenExpired() {
    if (!this.keycloakData.access_token) {
      return
    }
    return this.keycloakData.access_token.exp * 1000 < new Date().getTime()
  }

  /**
   *   DEV
   */

  downloadCertificate() {
    const userID = this.keycloakData.userDetails?.data.userId
    const url = this.httpBase.backendUrls.middlewareUrl + '/ejbca/api/ca/' + userID + '/1'
    this.http.get(url).subscribe({
      next: (res: any) => {
        const certificate = res.CACert
        const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(certificate)
        const dlAnchorElem = document.getElementById('downloadAnchorElem')
        if (!dlAnchorElem) {
          return
        }
        dlAnchorElem.setAttribute('href', dataStr)
        dlAnchorElem.setAttribute('download', `${userID}.crt`)
        dlAnchorElem.click()
      },
      error: (error: HttpErrorResponse) => {
        // TODO: This should be a dialog
        alert('Certificate Download Failed.')
        console.error(error)
      },
    })
  }

  // DateRange methods for new date picker (can't be on ChartService or EmmaService because we might want to use them elsewhere in the app)

  // TODO: This looks like it needs a refactor
  public dateRangeFromToken(token: DateRangeToken) {
    const validPeriods: ValidPeriods[] = ['D', 'W', 'M', 'Y']
    const parts = token.split(',')
    let start: Date | undefined, end: Date | undefined

    const extract = (part: string) => {
      const isCalendar = part.includes('C')
      part = part.replace('C', '')
      let period: ValidPeriods
      let amount: number
      for (const validPeriod of validPeriods) {
        if (part.includes(validPeriod)) {
          period = validPeriod
          amount = parseInt(part.replace(validPeriod, ''))
          return { isCalendar, period, amount }
        }
      }
    }

    if (parts.length > 1) {
      // Something ago
      const durationExtraction = extract(parts[0])
      const offsetExtraction = extract(parts[1])

      if (offsetExtraction?.isCalendar) {
        // TODO: Not sure if this will ever actually happen
      } else {
        switch (offsetExtraction?.period) {
          case 'D':
            start = startOfDay(new Date())
            start = addDays(start, -offsetExtraction.amount)
            break
          case 'W':
            start = startOfWeek(new Date(), { weekStartsOn: 1 })
            start = addWeeks(start, -offsetExtraction.amount)
            break
          case 'M':
            start = startOfMonth(new Date())
            start = addMonths(start, -offsetExtraction.amount)
            break
          case 'Y':
            start = startOfYear(new Date())
            start = addYears(start, -offsetExtraction.amount)
            break
        }
      }

      if (!start) {
        console.warn('Invalid date range token')
        return
      }

      switch (durationExtraction?.period) {
        case 'D':
          end = endOfDay(start)
          if (durationExtraction?.amount > 1) {
            end = addDays(end, durationExtraction?.amount)
          }
          break
        case 'W':
          end = endOfWeek(start, { weekStartsOn: 1 })
          if (durationExtraction?.amount > 1) {
            end = addWeeks(end, durationExtraction?.amount)
          }
          break
        case 'M':
          end = endOfMonth(start)
          if (durationExtraction?.amount > 1) {
            end = addMonths(end, durationExtraction?.amount)
          }
          break
        case 'Y':
          end = endOfYear(start)
          if (durationExtraction?.amount > 1) {
            end = addYears(end, durationExtraction?.amount)
          }
          break
      }
    } else {
      // Most recent (no offset)
      const extraction = extract(token)
      if (!extraction) {
        console.warn('Invalid date range token')
        return
      }

      if (extraction.isCalendar) {
        end = new Date()
        switch (extraction.period) {
          case 'D':
            start = addDays(end, -extraction.amount)
            break
          case 'W':
            start = addWeeks(end, -extraction.amount)
            break
          case 'M':
            start = addMonths(end, -extraction.amount)
            break
          case 'Y':
            start = addYears(end, -extraction.amount)
            break
        }
      } else {
        end = endOfDay(new Date())
        switch (extraction.period) {
          case 'D':
            start = startOfDay(end)
            break
          case 'W':
            start = startOfWeek(end, { weekStartsOn: 1 })
            break
          case 'M':
            start = startOfMonth(end)
            break
          case 'Y':
            start = startOfYear(end)
            break
        }
      }
    }

    if (!start || !end) {
      console.warn('Invalid date range token')
      return
    }

    return new DateRange<Date>(start, end)
  }

  public dateRangeIsToken(dateRange: DateRange<Date> | DateRangeStrings | DateRangeToken): dateRange is DateRangeToken {
    return !(dateRange.hasOwnProperty('start') && dateRange.hasOwnProperty('end')) && typeof dateRange === 'string'
  }

  public dateRangeIsRange(dateRange: DateRange<Date> | DateRangeStrings | DateRangeToken): dateRange is DateRange<Date> {
    return dateRange.hasOwnProperty('start') && dateRange.hasOwnProperty('end')
  }

  public dateRangeIsRangeStrings(dateRange: DateRange<Date> | DateRangeStrings | DateRangeToken): dateRange is DateRangeStrings {
    return dateRange.hasOwnProperty('start') && typeof (dateRange as DateRangeStrings).start === 'string'
  }

  /**
   *    Utils
   */
  parseLocationInputForLatLng(locationInput: string) {
    const regex = new RegExp(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/i)
    const isLongLat = regex.test(locationInput)
    if (isLongLat) {
      const [latString, lngString] = locationInput.replace(' ', '').split(',')
      const lat = parseFloat(latString)
      const lng = parseFloat(lngString)
      const address = locationInput
      return {
        lat,
        lng,
        address,
      }
    } else {
      return false
    }
  }

  getSupportEmail() {
    const fallback = environment.contactEmail

    const userCountry = this.keycloakData.userDetails?.data?.attributes?.country?.[0]
    if (!userCountry) {
      return fallback
    }
    const contractPartners = this.app.content.content.contractPartners
    if (!contractPartners) {
      return fallback
    }
    const partner = contractPartners.find((par) => par.fields.country === userCountry)
    if (!partner) {
      return fallback
    }
    const localEmail = partner.fields.supportEmailAddress
    if (!localEmail) {
      return fallback
    }
    return localEmail
  }

  showDataFetchingErrorModal() {
    this.statusOverlay.showStatus({
      message: this.translate.instant('EMMA_SERVICE.ERROR_FETCHING_DATA_HTML').replaceAll('#supportEmail#', this.getSupportEmail()),
      type: 'error',
      closeable: true,
    })
  }

  mailtoSupport() {
    const localEmail = this.getSupportEmail()
    window.location.href = `mailto:${localEmail}`
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  writeToClipboard(content: string) {
    window.navigator.clipboard.writeText(content)
  }

  // TODO: This should be a pipe so that it doesn't get repeatedly called by change detection
  public navItemChildVisible(child: NavItemChild | NavItem) {
    if (this.app.environment.frontend !== 'production') {
      return true
    }
    if (this.app.environment.frontend === 'production') {
      // for the Admin-Service Navigation Items
      if (child.id === 'navigation-admin-service-uuids') {
        return this.userHasAdminSectionFeatureFlag('uuid')
      }

      if (child.id === 'navigation-admin-service-device-types') {
        return this.userHasAdminSectionFeatureFlag('dt')
      }

      if (child.id === 'navigation-admin-service-firmware-versions') {
        return this.userHasAdminSectionFeatureFlag('fw')
      }

      // for every Navigation Item except the Admin-Service Items
      if (this.userHasFeatureFlag(child.showInProductionFlag)) {
        return true
      }

      if (child.hideInProduction) {
        return false
      }

      return true
    }
    return true
  }
}
